export default {
    scale: {
        max: 2.3,
        min: 1,
        speed: 0.2
    },
    translate: {
        speed: 1
    }

};