export {default as Article} from './Article';
export {default as Body} from './Body';
export {default as Gallery} from './Gallery';
export {default as Parallax} from './Parallax';
export {default as Pointer} from './Pointer';
export {default as Slideshow} from './Slideshow';
export {default as Space} from './Space';
export {default as Story} from './Story';
export {default as Title} from './Title';
export {default as Video} from './Video';
export {default as Img} from './Img';
export {default as Table} from './Table';
export {default as Persona} from './Persona';
export {default as Cartography} from './Cartography';